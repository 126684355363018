import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/authContext";
import profileService from "../services/profile.service";
import useSound from 'use-sound';
import { Avatar, Button } from "@mui/material";
import alertService from "../services/sweetAlert";
import './TeamSelection.css';
import { Link } from "react-router-dom";
import VideoPlayer from "../components/VideoPlayer";


const TeamSelection = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [winner, setWinner] = useState("");
  const cdn = 'https://files.geetsuhane.com/';
  const [playJoin] = useSound(cdn + 'sound/boat.mp3',{loop: true});
  const [playLose] = useSound(cdn + 'sound/lose.mp3');
  const [teams, setTeams] = useState([]);
  const [food, setFood] = useState(null);
  const [timeLeft, setTimeLeft] = useState("");
  const [stars, setStars] = useState([]);


  const getTeams = async () => {
    playJoin();
    var res = await profileService.getResult({
      qry: `CALL sp_getBoatRaceTeams('')`
    }).then(r => {
      // console.log(r?.[0])
     setTeams(r?.[0])
    });
  };
  const getStars = async () => {
    playJoin();
    var res = await profileService.getResult({
      qry: `CALL sp_boatRace('','top3','',0)`
    }).then(r => {
      // console.log(r?.[0])
      setStars(r?.[0])
    });
  };

  const getWinners = async () => {
    var res = await profileService.getResult({
      qry: `CALL sp_getBoatRaceWinners()`
    }).then(r => {
      //console.log(r?.[0]?.length)
       if(r?.[0]?.length>0)
      setWinner(r?.[0][0]?.pic)
    });
  };

  const fetchFood =async () => {
    var res = await profileService.getResult({
      qry: `CALL sp_food('','${currentUser?.gname}','${currentUser?.id}')`
    }).then(r => {
      //console.log(r?.[0][0])
      if(r?.[0]?.length>0) {
        if(r?.[0][0]?.value==='Breakfast') {
          setFood('boatrace/bf.gif');
        }else if(r?.[0][0]?.value==='Lunch') {
          setFood('boatrace/l1.gif');
        }else if(r?.[0][0]?.value==='Snacks') {
          setFood('boatrace/s1.gif');
        }else if(r?.[0][0]?.value==='Dinner') {
          setFood('boatrace/d1.gif');
        } else {
          setFood('');
        }
      }
     

    
    });

  }

  const handleGrabClick = async () => {
    var res = await profileService.getResult({
      qry: `CALL sp_food('grab','${currentUser?.gname}','${currentUser?.id}')`
    }).then(r => {
      console.log(r?.[0][0]?.res)
       alertService.info('Food', r?.[0][0]?.res);

     });
  }

  const calculateTimeLeft = () => {
    const now = new Date();
    const utc11pm = new Date(now);

    // Set to 11 PM UTC today
    utc11pm.setUTCHours(17, 0, 0, 0); 

    // If the current time has already passed 11 PM UTC, set the race start time to the next day
    if (now > utc11pm) {
        utc11pm.setUTCDate(utc11pm.getUTCDate() + 1); // Move to the next day
    }

    const difference = utc11pm - now;
    let timeLeft = "";

    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    timeLeft = `${hours}h ${minutes}m ${seconds}s`;

    setTimeLeft(timeLeft);
};


  useEffect(() => {
    async function fetchData() {
     await getTeams();
     await fetchFood ('');
     await getWinners();
     await getStars();
      calculateTimeLeft();

    }
    const timer = setInterval(calculateTimeLeft, 1000);
    fetchData();
    return () => clearInterval(timer); // Clear the interval on component unmount
  }, [currentUser]);

  return (
<div className="team-selection-container">
  <div className="top-controls">
    <div className="race-start-info strong">
    🌐🏆 Race starts in: {timeLeft}
    </div>
  </div>
  <div className="team-selection">

  {food?.length>0 &&  winner?.length===0 && (
      <div className="food-container">
       
        <img src={cdn + food} alt="Food" className="food-image" />
        <button className="grab-button" onClick={handleGrabClick}>Grab</button>
      </div>
    )}
    {winner?.length>0  && (
      <div className="food-container">
        <Avatar sx={{ width: 300, height: 300 }} src={cdn + winner}></Avatar>
        <div className="grab-button" >Congratulations!!</div>
      </div>
    )}
    {/* { winner?.length===0 && 
    <div className="video-container">
        <VideoPlayer></VideoPlayer>
    </div>
  } */}
 
    <div className="p-1 text-center">
      <Link  className="rank-button bg-primary text-center text-white font-xsssss m-1" to={`/search?searchBy=race&team=${currentUser?.gname}`}>Ranks</Link>
      <Link  className="rank-button bg-black text-center text-white font-xsssss m-1" to={`/raceresult/result`}>Last Race</Link>
      <Link  className="rank-button bg-mini-gradiant text-center text-white font-xsssss  m-1" to={`/raceresult/points`}>Points Table</Link>
    </div>
    
    <div className="d-flex justify-content-center">
    {stars.length > 0 && stars.map((star) => (
      <div className="p-1">
       <Avatar sx={{ width: 86, height: 86 }} src={star?.picture?.includes('googleuserconten')?star?.picture: cdn +'users/' + star?.picture}></Avatar>
       <div className="text-white font-xssss text-center">{star?.Score}</div>
      </div>
      ))}      
    </div>

    {teams?.length > 0 && food?.length===0 && teams?.map((team) => (
      <Link key={team?.id} to={`/search?searchBy=race&team=${team?.name}`}>
        <div className={`team-card`}>
          <div className="row">
            <div className="font-xssss text-red text-left counter feather-zap strong">
              {team?.points.toLocaleString()}
            </div>
          </div>
          <div className="text-center">
            <center>
              <Avatar sx={{ width: 75, height: 75 }} src={cdn + team?.pic}></Avatar>
            </center>
          </div>
        </div>
      </Link>
    ))}
  </div>
</div>

  );
};

export default TeamSelection;
